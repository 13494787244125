<!--  -->
<template>
  <div
    class="
      home
      page-template
      page-template-template-home
      page-template-template-home-php
      page
      page-id-11
      wpb-js-composer
      js-comp-ver-6.4.2
      vc_responsive
    "
  >
    <Headers></Headers>
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="main" class="site-main" role="main">
          <div class="content_container page">
            <!-- 头部banner图 -->
            <div class="section_banner_container">
              <div class="section_banner">
                <div class="container">
                  <div class="row">
                    <div class="col-xs-12 col-sm-11">
                      <div class="section_banner_content">
                        <h1 class="section_banner_heading">{{ page.title }}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 新闻列表 -->
            <div class="container">
              <div class="row">
                <div
                  class="col-sm-offset-1"
                  v-html="page.content"
                  style="font-size: 16px; width: 975px"
                ></div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    <!-- #content -->
    <Footers></Footers>
  </div>
</template>

<script>
import Headers from "../../components/header/header.vue";
import Footers from "../../components/footer/footer.vue";
import { queryPage } from "../../util/api.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Headers, Footers },
  metaInfo() {
    return {
      title: this.page.seoTitle + "- 盈喜集团股份有限公司|ATXG",
      meta: [
        { name: "keywords", content: this.page.seoKeywords },
        { name: "description", content: this.page.seoDescription },
      ],
    };
  },

  data() {
    //这里存放数据
    return {
      // 页面内容
      page: "",
      // 页面code
      pageId: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 请求内容
    async init() {
      // console.log(this.$route.params.id);
      const { data: res } = await this.$http.get(queryPage, {
        params: { code: "corporate_profile" },
      });
      console.log(res);
      this.page = res.result;
      this.pageId = "corporate_profile";
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.init();
    // console.log(this.$route.query.id)
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 监听地址变化
  watch: {
    $route(to, from) {
      // console.log(to.query.id, this.pageId)
      // console.log(to.query.id.indexOf(this.pageId))
      // if (to.query.id.indexOf(this.pageId) == -1) {
      this.init();
      // }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.page {
  text-align: left;
  .section_banner {
    background-image: url(../../../public/home/img/bg-section-banner-contact.jpg);
  }
}
</style>